/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.mat-mdc-snack-bar-container.snackbar-success {
    --mdc-snackbar-container-color: #00be5f;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
}

.mat-mdc-snack-bar-container.snackbar-error {
    --mdc-snackbar-container-color: #ff3a3a;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
}